import { Button } from "@mui/material";
import { setDocumentTitle } from "../router";

const APP_URL = process.env.REACT_APP_URL;

export default function Home() {
	setDocumentTitle();
	return (
		<div style={{ background: "#6699CC", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
			<a href={APP_URL}>
				<img
					alt="Lab Axiom"
					src="/images/lab-axiom-logo-tall.svg"
					style={{ filter: "brightness(0) saturate(100%) invert(1)" }}
					width="300px"
				>
				</img>
			</a>
			<Button
				color="light"
				href={APP_URL}
				style={{ marginTop: "16px", width: "300px" }}
				variant="contained"
			>
				Log In
			</Button>
			<Button
				color="light"
				href="https://support.labaxiom.com/hc/en-us/requests/new"
				style={{ marginTop: "16px", width: "300px" }}
				variant="outlined"
			>
				Sign Up
			</Button>
			<Button
				color="light"
				href="https://support.labaxiom.com/hc/en-us"
				style={{ marginTop: "16px", width: "300px" }}
				variant="outlined"
			>
				Support
			</Button>
		</div>
	);
}