import React from "react";
import { Add as AddIcon, Send as SendIcon, Print as PrintIcon, RestartAlt as RestartIcon } from "@mui/icons-material";
import { Button, TextField, Grid2 as Grid } from "@mui/material";
import { Form } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import "./sample.css";
import { setDocumentTitle } from "../router";
import { useParams } from "react-router-dom";

const APP_URL = process.env.REACT_APP_URL;
const APP_API_URL = process.env.REACT_APP_API_URL;

export default function Sample() {
	const [formCompleted, setFormCompleted] = React.useState(false);
	const [formDisabled, setFormDisabled] = React.useState(false);
	const title = "Sample Submission";
	const { tenantId } = useParams();
	const encodedLabLogo = window.location.search.substring(1).split("&").find(param => param.startsWith("labLogo="))?.split("=")[1];
	const labLogo = encodedLabLogo ? decodeURIComponent(encodedLabLogo) : null;
	const encodedLabName = window.location.search.substring(1).split("&").find(param => param.startsWith("labName="))?.split("=")[1];
	const labName = encodedLabName ? decodeURIComponent(encodedLabName) : null;
	const encodedLabAddress = window.location.search.substring(1).split("&").find(param => param.startsWith("labAddress="))?.split("=")[1];
	const labAddress = encodedLabAddress ? decodeURIComponent(encodedLabAddress) : null;
	const encodedLabEmail = window.location.search.substring(1).split("&").find(param => param.startsWith("labEmail="))?.split("=")[1];
	const labEmail = encodedLabEmail ? decodeURIComponent(encodedLabEmail) : null;
	const encodedLabPhone = window.location.search.substring(1).split("&").find(param => param.startsWith("labPhone="))?.split("=")[1];
	const labPhone = encodedLabPhone ? decodeURIComponent(encodedLabPhone) : null;
	const [customerEmail, setCustomerEmail] = React.useState("");
    const [samples, setSamples] = React.useState([{
        name: "",
        lotNumber: "",
        note: ""
    }]);

	React.useEffect(() => {
		setDocumentTitle(title);
	}, [title]);

    const addSample = () => {
        setSamples([...samples, {
            name: "",
            lotNumber: "",
            note: ""
        }]);
    };

	const removeSample = (indexToRemove) => {
        setSamples(samples.filter((_, index) => index !== indexToRemove));
    };

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		if (formDisabled) return;
		try {
			setFormDisabled(true);
            const formData = new FormData(event.target);
			const userId = formData.get("email");
			const tenantId = formData.get("tenantId");
			const data = samples.map((_, index) => ({
				userId: userId,
				tenantId: tenantId,
				name: formData.get(`name-${index}`),
				lotNumber: formData.get(`lotNumber-${index}`),
				note: formData.get(`note-${index}`)
			}));
			console.log(data);
			const response = await fetch(APP_API_URL + "/sample/submit", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			});
			console.log(response);
			if (!response.ok) throw new Error(response.statusText);
			const json = await response.json();
			console.log(json);
			const samplesArray = Array.isArray(json.result) ? json.result : [json.result];
			setSamples(samplesArray);
			setFormCompleted(true);
		} catch (error) {
			console.error(error);
		} finally {
			setFormDisabled(false);
		}
	};

	if (formCompleted) {
		return (
			<Grid id="SampleSubmissionContainer" container rowSpacing={2} columnSpacing={4}>
				<SampleHeader labLogo={labLogo} labName={labName} title={title} />
				<Grid size={{ xs: 12, sm: 6 }}>
					<div className="section-header">
						<h2>Instructions</h2>
					</div>
					<p>Thanks for providing information about your sample. Here are the next steps:</p>
					<ol>
						<li>Print this receipt.</li>
						<li>Sign the printed receipt.</li>
						<li>Send the signed receipt with your sample.</li>
					</ol>
				</Grid>
				<Grid size={{ xs: 12, sm: 6 }}>
					<div className="section-header">
						<h2>Lab</h2>
					</div>
					<p><b>{labName}</b></p>
					{labAddress && (
						<div style={{ marginTop: "8px" }}>
							{labAddress.split(",").map((line, index) => (
								<span key={index}>{line}<br /></span>
							))}
						</div>
					)}
					{labEmail && (
						<div style={{ marginTop: "8px" }}>{labEmail}</div>
					)}
					{labPhone && (
						<div style={{ marginTop: "8px" }}>{labPhone}</div>
					)}
				</Grid>
				<Grid size={12}>
					<div className="section-header">
						<h2>Customer</h2>
					</div>
					<p><b>{customerEmail}</b></p>
				</Grid>
				{samples.map((sample, index) => (
					<SampleOutputSection key={index} sample={sample} />
				))}
				<Grid className="sample-submission-agreement" size={12}>
					<div className="section-header">
						<h2>Agreement</h2>
					</div>
					<p style={{ fontSize: "12px", lineHeight: "15px" }}>
						I, the undersigned, understand that {labName} will bill me for all
						tests that it performs on this sample, as described in this document.
						I agree to pay in full all bills for tests that {labName} performs
						on this sample and samples that I submit in the future.
						If I request changes after {labName} has begun tests that this document
						describes, I accept that I am still responsible to pay in full all
						bills for the tests that {labName} has already begun.
						I understand that {labName} may refuse to perform tests on any sample,
						that {labName} will not perform tests on this sample until I submit
						this signed document, and that if I do not wish to be bound by this
						agreement, I should not submit this sample or this document.
					</p>
				</Grid>
				<Grid className="sample-submission-agreement" size={{ xs: 12, sm: 6 }} style={{ borderBottom: "1px solid black" }}>
					Signature:<br /><br />
				</Grid>
				<Grid className="sample-submission-agreement" size={{ xs: 12, sm: 6 }} style={{ borderBottom: "1px solid black" }}>
					Date:<br /><br />
				</Grid>
				<Grid className="sample-submission-action" size={12}>
					<Button
						onClick={() => {
							window.print();
						}}
						startIcon={<PrintIcon />}
						sx={{ marginTop: "16px" }}
						variant="contained"
					>
						Print
					</Button>
					<Button
						onClick={() => {
							setSamples([{}]);
							setFormCompleted(false);
						}}
						startIcon={<RestartIcon />}
						sx={{ marginTop: "16px" }}
						variant="contained"
					>
						Reset
					</Button>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Form
				onKeyDown={(e) => {
					const elementType = document.activeElement.type;
					if (e.key === "Enter" && elementType !== "submit" && elementType !== "button" && elementType !== "textarea") e.preventDefault();
				}}
				onSubmit={handleFormSubmit}
			>
				<input type="hidden" name="tenantId" value={tenantId}></input>
				<Grid id="SampleSubmissionContainer" container spacing={2}>
					<SampleHeader labLogo={labLogo} labName={labName} title={title} />
					<Grid size={12}>
						<div className="section-header">
							<h2>Customer</h2>
						</div>
					</Grid>
					<Grid size={12}>
						<TextField
							label="Customer Email"
							name="email"
							onChange={(e) => setCustomerEmail(e.target.value)}
							required
							type="email"
							value={customerEmail}
						></TextField>
					</Grid>
					{samples.map((sample, index) => (
						<SampleInputSection 
							canRemove={samples.length > 1} 
							index={index}
							key={index}
							onRemove={() => removeSample(index)}
							sample={sample}
							setSamples={setSamples}
							formDisabled={formDisabled}
						/>
					))}
					<Grid className="sample-submission-action" size={12}>
						<Button disabled={formDisabled} onClick={addSample} startIcon={<AddIcon />} sx={{ marginTop: "16px" }} variant="contained">
							Add Sample
						</Button>
						<Button type="submit" disabled={formDisabled} startIcon={<SendIcon />} sx={{ marginTop: "16px" }} variant="contained">
							Send
						</Button>
					</Grid>
				</Grid>
			</Form>
		);
	}
}

function SampleInputSection({ canRemove, index, onRemove, sample, setSamples, formDisabled }) {
	return (
		<>
			<Grid size={12}>
				<div className="section-header">
					<h2>Sample</h2>
					{canRemove && (
						<Button 
							onClick={onRemove}
							size="small"
							variant="outlined"
							disabled={formDisabled}
						>
							Remove
						</Button>
					)}
				</div>
			</Grid>
			<Grid size={12}>
				<TextField
					label="Sample Name"
					name={`name-${index}`}
                    onChange={(e) => setSamples(prev => {
                        const newSamples = [...prev];
                        newSamples[index] = { ...newSamples[index], name: e.target.value };
                        return newSamples;
                    })}
					required
					type="text"
                    value={sample.name || ""}
				></TextField>
			</Grid>
			<Grid size={12}>
				<TextField
					label="Lot Number"
					name={`lotNumber-${index}`}
					onChange={(e) => setSamples(prev => {
						const newSamples = [...prev];
						newSamples[index] = { ...newSamples[index], lotNumber: e.target.value };
						return newSamples;
					})}
					type="text"
					value={sample.lotNumber || ""}
				></TextField>
			</Grid>
			<Grid size={12}>
				<TextField
					label="Tests and Specifications Requested"
					name={`note-${index}`}
					onChange={(e) => setSamples(prev => {
						const newSamples = [...prev];
						newSamples[index] = { ...newSamples[index], note: e.target.value };
						return newSamples;
					})}
					multiline
					rows={5}
					required
					type="text"
					value={sample.note || ""}
				></TextField>
			</Grid>
		</>
	);
}

function SampleOutputSection({ sample }) {
	return (
		<>
			<Grid size={12}>
				<div className="section-header">
					<h2>Sample</h2>
				</div>
			</Grid>
			<Grid size={{ xs: 12, sm: 6 }}>
				<div><b>{sample.name}</b></div>
				<div style={{ marginTop: "8px" }}>ID: {sample.id}</div>
				<div style={{ marginTop: "8px" }}>Code: {getCode(sample.createdAt, sample.updatedAt)}</div>
				{sample.lotNumber && (
					<div style={{ marginTop: "8px" }}>Lot Number: {sample.lotNumber}</div>
				)}
				<div style={{ marginTop: "8px" }}>Created: {sample.createdAt?.split("T")[0]}</div>
				<div style={{ marginTop: "8px" }}>Tests and Specifications Requested:</div>
				<div style={{ marginTop: "8px" }}>{sample.note}</div>
			</Grid>
			<Grid className="sample-submission-qr-code" size={{ xs: 12, sm: 6 }}>
				<div><QRCodeSVG value={`${APP_URL}/sample/view/${sample.tenantIdShard}|${sample.id}`} /></div>
			</Grid>
		</>
	);
}

function SampleHeader(props) {
	const labLogo = props.labLogo;
	const labName = props.labName;
	const title = props.title;
	return (
		<Grid size={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
			<img id="SampleSubmissionLogo" src={labLogo} alt={labName} />
			<h1>{title}</h1>
		</Grid>
	);
}

function getCode(createdAt, updatedAt) {
	const date = new Date(createdAt ? createdAt : updatedAt);
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const hour = date.getHours();
	const minute = date.getMinutes();
	const second = date.getSeconds();
	return month.toString().padStart(2, "0") + day.toString().padStart(2, "0") +
		hour.toString().padStart(2, "0") + minute.toString().padStart(2, "0") + second.toString().padStart(2, "0");
}